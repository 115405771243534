import React from 'react'
import { Layout } from '../components/layout'
import { ReturnBar } from '../components/Navbar/ReturnBar'
import {RowStrip} from '../components/rows/rowstrip'
import {RowItem} from '../components/rows/rowitem'
import {RowItemHeader} from '../components/rows/rowitemheader'
import fastphoto from '../images/fastphoto.png';
import peercoin from '../images/peercoin.png';


const ProjectPage = () => {
    return(
        <Layout title="Austin Loza - Projects">
            <ReturnBar color={"green"} />
            <h1 className="text-7xl text-center pb-10">Projects</h1>
            <RowStrip>
                <RowItem>
                    <img src={fastphoto} className='w-1/2' alt='software window' />
                </RowItem>
                
                <RowItem>
                    <RowItemHeader>
                        FastPhoto
                    </RowItemHeader>
                    <h4 className='flex flex-row justify-center font-bold'>Developed with: WPF, C#</h4>
                    <div className='px-10 py-5'>
                        A tool for photographers to take photos in person at events
                        and print them immediately on site.
                        <br/><br/>
                        It applies PNG overlays to the photos if desired, allowing for
                        watermarks, borders, and digital frames to be applied to a photo 
                        and then printed in seconds.
                    </div>
                </RowItem>
            </RowStrip>
            <hr solid className='opacity-10 w-4/5 text-center m-auto' />
            <RowStrip>
                <RowItem>
                    <RowItemHeader>
                        PeerCoin
                    </RowItemHeader>
                    <h4 className='flex flex-row justify-center font-bold'>Developed with: C++, Python</h4>
                    <div className='px-10 py-5'>
                        A blockchain-based proof of concept for a bittorrent-like
                        peer-to-peer filesharing system without the risk of
                        leeches.
                        <br/><br/>
                        Users earn "gas" for hosting files and can spend it to
                        download other files, to enforce a (at minimum) 1:1 download
                        to upload ratio.
                    </div>
                </RowItem>

                <RowItem>
                    <img src={peercoin} className='w-1/2' alt='peercoin logo' />
                </RowItem>
            </RowStrip>
        </Layout>
    )
}

export default ProjectPage